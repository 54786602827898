<template>
  <v-row class="fill-height flex-column ma-2">
    <Nav />
    <transition name="slide-x-transition" mode="out-in">
      <router-view />
    </transition>
    <Footer />
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import axios from 'axios'
axios.defaults.withCredentials = true
export default {
  data() {
    return {};
  },
  components: {
    Footer: () => import("@/components/Footer.vue"),
    Nav: () => import("@/components/Nav.vue"),
  },
  methods: {
    ...mapActions([
      "fetchAgents",
      "fetchLinks",
      "fetchStocks",
      "fetchMemberVerify",
      "fetchUsers",
      "fetchVerifySessions",
    ]),
  },
  mounted() {
    this.fetchAgents();
    this.fetchUsers();
    this.fetchVerifySessions();
    this.fetchLinks();
    this.fetchStocks();
    this.fetchMemberVerify();
  },
};
</script>